<template>
  <b-card :text-variant="type">
    <b-card-header>
      <b-card-title v-if="title">{{ title }}</b-card-title>
    </b-card-header>
    <b-card-body>
      <b-card-text v-if="message">{{ message }}</b-card-text>
      <div v-if="subTitle" class="h5 text-primary mt-1">{{ subTitle }}</div>
    </b-card-body>
    <b-card-footer>
      <count-down-button
        :title="buttonTitle"
        @zero="redirect()"
        @click="redirect()"
      />
    </b-card-footer>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardBody,
  BCardHeader,
  BCardTitle,
  BCardText,
  BCardFooter,
} from "bootstrap-vue";
import CountDownButton from "@/layouts/components/buttons/CountDownButton";

export default {
  components: {
    CountDownButton,
    BCard,
    BCardHeader,
    BCardBody,
    BCardTitle,
    BCardText,
    BCardFooter,
  },
  props: {
    title: String,
    subTitle: String,
    message: String,
    type: String,
    redirectTo: String,
    buttonTitle: {
      type: String,
      default: "Ok",
    },
  },
  methods: {
    redirect() {
      this.$router.replace(this.redirectTo ?? "/");
    },
  },
};
</script>

<style scoped></style>
